<template>
  <card-component-vue>
    <img src="../../assets/logo-kanggo_shell.png" style="width: 40%; justify-content: center;" class="mb-3">
    <b-field label="Email">
      <b-input type="email" v-model="email" maxlength="30"></b-input>
    </b-field>
    <b-field label="Password">
      <b-input type="password" v-model="password" password-reveal></b-input>
    </b-field>
    <div style="text-align: right; margin-top: 20px; margin-bottom: 10px;">
      <b-button class="button is-info is-outlined" @click="login()" style="width: 200px;">Log In</b-button>
    </div>
  </card-component-vue>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import CardComponentVue from '../../components/CardComponent.vue'

export default defineComponent({
  name: 'Login',
  components: {
    CardComponentVue
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      isLogin: 'auth/isLogin',
      user: 'auth/user',
      token: 'auth/token'
    })
  },
  watch: {
    isLogin (newVal, oldVal) {
      if (newVal === true) {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  data () {
    return {
      email: null,
      password: null,
      branchSelected: {
        branch_id: 1, branch: 'none'
      },
      lokasiLogin: [
        { branch_id: 1, branch: 'Q-BIG BSD' },
        { branch_id: 2, branch: 'Fatmawati' },
        { branch_id: 3, branch: 'Gading Serpong' },
        { branch_id: 4, branch: 'Iskandar Bogor' },
        { branch_id: 5, branch: 'Jatimakmur' }
      ]
    }
  },
  methods: {
    login () {
      this.loading = true
      if (this.email === null && this.password === null) {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Email & Password tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      if (this.email === null) {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Email tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      if (this.password === null) {
        this.$buefy.toast.open({
          duration: 2000,
          message: 'Password tidak boleh kosong!',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      }

      const userData = {
        email: this.email,
        password: this.password,
        branch: this.branchSelected,
        url: this.url
      }

      this.$store.dispatch('auth/login', userData).then((response) => {
        this.$router.push({ path: '/' })
      }).catch((error) => {
        this.$buefy.toast.open({
          duration: 2000,
          message: error.response.data.message,
          position: 'is-bottom',
          type: 'is-danger'
        })
        return false
      })
    }
  },
  mounted () {
    const rs = localStorage.getItem('webapp_b2b_imajihaus')
    const auth = JSON.parse(rs)
    if (auth && auth.token) {
      this.$store.commit('auth/setLoginStatus', true)
      this.$router.push({ path: '/' })
    }
  }
})
</script>

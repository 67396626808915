<template>
    <section class="section hero is-fullheight is-error-section">
        <div class="columns is-centered">
            <div class="column is-two-fifths">
                <router-view />
            </div>
        </div>
    </section>
  </template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FullPage',
  created () {
    this.$store.dispatch('toggleFullPage', true)
  },
  beforeDestroy () {
    this.$store.dispatch('toggleFullPage', false)
  }
})
</script>
